import barba from '@barba/core';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

import NavMobile from "./nav-mobile";
import Slider from "./slider";
// import ImageSlider from "./image-slider";
// import Header from "./header";
import Reveal from "./reveal";
import Cursor from "./cursor";
import Hero from "./hero";

export default class BarbaJs {

  constructor() {
    const body = document.querySelector('body');
    const cursor = document.getElementById('cursor');


    barba.init({
      timeout: 6000,

      // debug: true,
      // cacheIgnore: true,
      // prefetchIgnore: true,

      // requestError: (trigger, action, url, response) => {
      //   console.log({
      //     trigger: trigger,
      //     action: action,
      //     url: url,
      //     response: response,
      //   })
      // },


      prevent: ({ el }) => el.classList && (el.classList.contains('prevent-barba') || el.classList.contains('active') || el.target == '_blank'),

      transitions: [
        {
          name: 'default-transition',

          once(data) {
            setTimeout(() => {
              new Reveal('.js--reveal');
              new Slider('.js--slider');
              new Cursor();
              new Hero();
            }, 1);

            const foldPulls = document.querySelectorAll('.fold-bottom-pull');
            foldPulls.forEach((fold) => {
              if (fold) {
                const tween = gsap.to(fold, {
                  scrollTrigger: {
                    // markers: true,
                    trigger: fold,
                    scrub: 1,
                    start: "top " + fold.getBoundingClientRect().top,
                    end: "bottom top"
                  },
                  duration: 1,
                  translateY: '+=200px',
                });
              }
            });


            return gsap.to(body, {
              opacity: 1,
              duration: 1,
              delay: 0.5,
            });
          },


          beforeLeave(data) {
            // headerNavigation.classList.add('fade-out');
            if (ScrollTrigger.getById('artists-grid')) {
              ScrollTrigger.getById('artists-grid').kill();
            }

            return gsap.to(data.current.container, {
              opacity: 0,
              duration: 0.5,
              delay: data.trigger.classList !== undefined && data.trigger.classList.contains('navigation--header-link') ? 0.5 : 0.4,
            });
          },


          afterLeave(data) {
            window.scrollTo({
              top: 0,
              left: 0,
            });

            cursor.classList.remove('cursor-active');

            // setTimeout(() => {
            //   headerNavigation.classList.remove('fade-out');
            // }, 500);

            // update body classes
            body.classList.toggle('is-home', data.next.namespace === 'home');
            body.classList.toggle('not-home', data.next.namespace !== 'home');
          },


          beforeEnter(data) {
            data.next.container.style.opacity = 0;
          },


          enter(data) {
            data.current.container.remove();

            // reinit JS
            setTimeout(() => {
              new Reveal('.js--reveal');
              new Slider('.js--slider');
              new Cursor();
              new Hero();
            }, 1);

          },

          afterEnter(data) {
            return gsap.to(data.next.container, {
              opacity: 1,
              duration: 1,
              delay: 0.75,
              onComplete: () => {
                ScrollTrigger.refresh();
              },
            });
          }

        },
      ],

    });
  }

}
