import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class Hero {

  constructor(selector) {
    this.wrapper = document.querySelector('.hero');
    if (!this.wrapper) {
      return;
    }

    this.timelines = [];

    this.heroTop = this.wrapper.querySelector('#hero-top');
    this.heroBottom = this.wrapper.querySelector('#hero-bottom');

    // this.heroLine1 = this.wrapper.querySelector('.typo-open');
    // this.heroLine1 = this.wrapper.querySelector('.typo-open');
    // this.heroLine2 = this.wrapper.querySelector('.typo-festival');
    // this.heroLine3 = this.wrapper.querySelector('.typo-mainz');
    // this.heroLine4 = this.wrapper.querySelector('.typo-24');

    // this.heroLinesElements = this.wrapper.querySelectorAll('.typo-line');

    // window.addEventListener('resize', () => {
    //   if (this.delayedCall) {
    //     this.delayedCall.kill();
    //   }
    //   this.delayedCall = gsap.delayedCall(0.2, this.resizeHandler, [this]);
    // });

    // this.updateBoundingClientRects();
    // this.initTimelines();

    // pin typo container
    ScrollTrigger.create({
      trigger: '#hero',
      start: 'top top',
      end: 'bottom top',
      pin: '#hero-top',
    });

  }


  // resizeHandler(self) {
  //   self.updateBoundingClientRects();
  //   self.initTimelines();
  //   self.delayedCall = null;
  // }


  // initTimelines() {
  //   // kill and remove timelines
  //   this.timelines.forEach((timeline, index) => {
  //     timeline.kill();
  //   });
  //   this.timelines = [];

  //   // init timeline per line
  //   this.heroLines.forEach((lineData, index) => {
  //     let tl = gsap.timeline({
  //       scrollTrigger: {
  //         trigger: "#hero",
  //         start: this.heroBottomRect.top + 'px ' + (lineData.rect.top + lineData.rect.height + 20) + 'px',
  //         end: '+=' + lineData.rect.height + 'px',
  //         scrub: 0.1,
  //         // markers: true,
  //       },
  //     });
  //     tl.to(lineData.element, {
  //       transformOrigin: "top left",
  //       scaleY: 0,
  //       // skewX: index % 2 === 0 ? '25deg' : '-25deg',
  //       // opacity: 0,
  //       ease: "none",
  //     });

  //     this.timelines.push(tl);
  //   });
  // }


  // updateBoundingClientRects() {
  //   this.heroBottomRect = this.heroBottom.getBoundingClientRect();

  //   this.heroLines = [];
  //   this.heroLinesElements.forEach((line) => {
  //     this.heroLines.push({
  //       element: line,
  //       rect: line.getBoundingClientRect(),
  //     });
  //   });

  //   ScrollTrigger.refresh();
  // }

}
