import Cookies from "js-cookie";

class DarkmodeSwitcher extends HTMLElement {

  constructor() {
    super();

    this.html = document.querySelector('html');
    this.toggle = this.querySelector('.darkmode-toggle');

    this.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      this.html.classList.toggle('dark');
      this.toggle.classList.toggle('translate-x-4', this.html.classList.contains('dark'));
      Cookies.set('darkmode', this.html.classList.contains('dark') === true ? 1 : 0, { sameSite: 'strict' });
      this.html.dispatchEvent(new CustomEvent('darkmode_change'));
    });

    this.toggle.classList.toggle('translate-x-4', this.html.classList.contains('dark'));
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('darkmode-switcher', DarkmodeSwitcher);
}
