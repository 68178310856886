import { gsap } from "gsap/all";
import Cookies from "js-cookie";

class NavigationToggle extends HTMLElement {

  constructor() {
    super();

    this.html = document.querySelector('html');
    this.body = document.querySelector('body');
    this.navigation = document.querySelector('.navigation--header');
    this.lens = this.querySelector('.eye-animate-lens');
    this.active = false;

    this.baseAnimationTimeline = gsap.timeline({
      repeat: -1,
      repeatDelay: 10
    });
    this.baseAnimationTimeline.to(this.lens, { translateX: '-12px', scaleX: 0.8,   transformOrigin: "50% 50%", duration: 0.25 }, ">2");
    this.baseAnimationTimeline.to(this.lens, { translateX: '0px', scaleX: 1,   transformOrigin: "50% 50%", duration: 0.25 }, ">1");
    this.baseAnimationTimeline.to(this.lens, { translateX: '12px', scaleX: 0.8,   transformOrigin: "50% 50%", duration: 0.25 }, ">5");
    this.baseAnimationTimeline.to(this.lens, { translateX: '0px', scaleX: 1,   transformOrigin: "50% 50%", duration: 0.25 }, ">2");


    this.addEventListener('mouseenter', (event) => {
      if (!this.active) {
        this.baseAnimationTimeline.pause();
        this.baseAnimationTimeline.seek(0);
        gsap.to(this.lens, { translateX: '12px', scaleX: 0.8, transformOrigin: "50% 50%", duration: 0.25 });
      }
    });

    this.addEventListener('mouseleave', (event) => {
      if (!this.active) {
        gsap.to(this.lens, { translateX: '0px', scaleX: 1, transformOrigin: "50% 50%", duration: 0.25, onComplete: () => {
          this.baseAnimationTimeline.play();
        }});
      }
    });

    this.body.addEventListener('click', (event) => {
      if (this.active) {
        this.active = false;
        setTimeout(() => {
          this.classList.replace('z-[220]', 'z-[80]');
        }, 500)
        setTimeout(() => {
          this.html.classList.remove('navigation__active');
          this.html.classList.remove('overflow-hidden');
        }, event.target.classList.contains('navigation--header-link') ? 1000 : 0)
        gsap.to(this.lens, {translateX: '0px', scaleX: 1, transformOrigin: "50% 50%", duration: 0.25, onComplete: () => {
            this.baseAnimationTimeline.play();
        }});
      }
      else {
        if (event.target == this) {
          this.active = true;
          this.classList.replace('z-[80]', 'z-[220]');
          this.html.classList.add('navigation__active');
          this.html.classList.add('overflow-hidden');

          this.baseAnimationTimeline.pause();
          this.baseAnimationTimeline.seek(0);
          gsap.to(this.lens, { translateX: '-12px', scaleX: 0.8, transformOrigin: "50% 50%", duration: 0.25 });
        }
      }

      this.setAttribute('aria-pressed', this.active);
      this.setAttribute('aria-label', this.active ? 'Navigation ausblenden' : 'Navigation einblenden');
      this.navigation.setAttribute('aria-hidden', !this.active);
    });
  }

}


// Define the new web component
if ('customElements' in window) {
  customElements.define('navigation-toggle', NavigationToggle);
}
