export default class Cursor {

  constructor() {
    // document.querySelector('html').classList.remove('!cursor-none');
    // return;

    this.cursor = document.querySelector('#cursor');
    if (!this.cursor || this.isTouchDevice()) {
      document.querySelector('html').classList.remove('!cursor-none');
      return;
    }

    this.cursorInit = false;
    this.targets = document.querySelectorAll(':any-link, button, .scrollup, label, .darkmode-switcher, .navigation-toggle');
    this.footer = document.querySelector('footer');
    this.footerButton = document.querySelector('.footer--button');
    this.cookieModal = document.querySelector('.cookie-modal');

    // cursor position
    if (!this.cursor.dataset.initialized) {
      window.addEventListener('mousemove', (event) => {
        if (!this.cursorInit) {
          this.cursor.classList.remove('opacity-0');
          this.cursor.classList.add('opacity-100');
          this.cursorInit = true;
        }
        this.cursor.style.left = event.clientX + 'px';
        this.cursor.style.top = event.clientY + 'px';
        this.cursor.dataset.initialized = 1;
      });
    }


    this.targets.forEach((target) => {
      target.addEventListener('mouseover', (event) => {
        this.cursor.classList.add('cursor-active');
      });
      target.addEventListener('mouseout', (event) => {
        this.cursor.classList.remove('cursor-active');
      });
    });

    // if (this.footer) {
    //   this.footer.addEventListener('mouseover', (event) => {
    //     this.cursor.classList.add('!bg-white');
    //   });
    //   this.footer.addEventListener('mouseout', (event) => {
    //     this.cursor.classList.remove('!bg-white');
    //   });
    // }

    // if (this.footerButton) {
    //   this.footerButton.addEventListener('mouseover', (event) => {
    //     this.cursor.classList.add('!opacity-0');
    //   });
    //   this.footerButton.addEventListener('mouseout', (event) => {
    //     this.cursor.classList.remove('!opacity-0');
    //   });
    // }

    // if (this.cookieModal) {
    //   this.cookieModal.addEventListener('mouseover', (event) => {
    //     if (document.querySelector('body').classList.contains('background-white') || document.querySelector('body').classList.contains('background-orange')) {
    //       this.cursor.classList.add('!bg-white');
    //     }
    //   });
    //   this.cookieModal.addEventListener('mouseout', (event) => {
    //     if (document.querySelector('body').classList.contains('background-white') || document.querySelector('body').classList.contains('background-orange')) {
    //       this.cursor.classList.remove('!bg-white');
    //     }
    //   });
    // }
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  }

}
